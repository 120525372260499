import { SDKWrapper } from "./directus/SDKWrapper";

import { createItem, readItems, readItem, updateItem } from "@directus/sdk";

import { plurification } from '@/lib/helpers';

import { Button } from "@/components/capacitor";

import Bugsnag from '@bugsnag/js';

export class GameWrapper {
    constructor(gameID, user, localStorageObject, setLocalStorageObject) {
        this.gameID = gameID;
        this.user = user;
        this.sdkWrapper = new SDKWrapper(localStorageObject, setLocalStorageObject);

        this.answeredQuestionsIdFields = [
            'id',
            'question.id',
            'user_was_right',
        ];

        this.questionEntryFields = [
            'id',
            'game.id',
            'user.id',
            'user.email',
            'question_entries.*',
            ...this.answeredQuestionsIdFields.map((field) => `question_entries.answered_questions_id.${field}`),
        ];

        this.questionFields = [
            'id',
            'name',
            'answer',
        ];
        this.categoryFields = [
            'id',
            'icon',
            'name',
            ...this.questionFields.map((field) => `questions.${field}`),
        ];
        this.levelFields = [
            'id',
            'name',
            'icon',
            ...this.categoryFields.map((field) => `categories.${field}`),
        ];
        this.gameFields = [
            'id',
            'name',
            'icon',
            ...this.levelFields.map((field) => `levels.${field}`),
        ];
    }



    // Directus functions
    getGame(cards = false) {
        return new Promise(async (resolve, reject) => {
            try {
                const game = await this.sdkWrapper.request(readItem('games', this.gameID, {
                    fields: this.gameFields,
                    sort: [
                        'sort',
                        'date_created',
                        'levels.sort',
                        'levels.date_created',
                        'levels.categories.sort',
                        'levels.categories.date_created',
                        'levels.categories.questions.sort',
                        'levels.categories.questions.date_created',
                    ],
                }));

                // Get role by user
                const roleName = this.sdkWrapper.getRoleName(this.user);

                if (cards) {
                    let gameEntry = null;
                    try {
                        gameEntry = await this.sdkWrapper.getGameEntryByUserAndGameId(this.user.id, this.gameID);
                    } catch (error) {
                        const HandledError = new Error(`Catched: ${(typeof error === 'string') ? error : error.message}`);
                        Bugsnag.notify(HandledError);
                        console.error('Error -> GameWrapper.getGame().getGameEntryByUserAndGameId()', error);
                    }

                    let gameCards = null;
                    if (roleName === 'Gebruiker') {
                        gameCards = this.recursiveCreateUserCards(gameEntry, [game]);
                    } else if (roleName === 'Administrator' || roleName === 'Admin') {
                        console.error('TODO');
                        
                        gameCards = this.recursiveCreateAdminCards(gameEntry, [game]);
                    }

                    resolve(gameCards);
                } else {
                    resolve(game);
                }
            } catch (error) {
                const HandledError = new Error(`Catched: ${(typeof error === 'string') ? error : error.message}`);
                                                    Bugsnag.notify(HandledError);
                console.error('Error -> GameWrapper.getGame()', error);

                reject(error);
            }
        });
    }

    resetGame() {
        return new Promise(async (resolve, reject) => {
            try {
                await this.sdkWrapper.createGameEntry(this.user.id, this.gameID);
                
                resolve();
            } catch (error) {
                const HandledError = new Error(`Catched: ${(typeof error === 'string') ? error : error.message}`);
                Bugsnag.notify(HandledError);
                console.error('Error -> GameWrapper.resetGame().createGameEntry()', error);

                reject(error);
            }
        });
    }



    // Global functions
    calculateProgress(firstLength, secondLength) {
        return Math.round((firstLength / secondLength) * 10000) / 100;
    }



    // Dashboard functions
    generateQuestionsArray(levels) {
        const questions = levels.reduce((acc, level) => {
            return acc.concat(level.categories.reduce((acc, category) => {
                return acc.concat(category.questions.map((question) => {
                    return {
                        ...question,
                        levelId: level.id,
                        categoryId: category.id,
                    };
                }));
            }, []));
        }, []);

        return questions;
    }

    generateAnswererdQuestionsArray(allQuestions, gameEntry) {
        const answeredQuestions = gameEntry.question_entries.map((entry) => {
            const question = allQuestions.find((question) => {
                return question.id === entry.answered_questions_id.question.id;
            });

            if (!question) {
                return null;
            }

            return {
                ...question,
                ...entry.answered_questions_id,
            };
        });

        return answeredQuestions.filter((question) => question !== null);
    }

    recursiveCreateAdminCards(data) {
        // TODO: Fix this function
        // return data.map((item) => {
        //     const newCard = {
        //         id: item.id || null,
        //         name: item.name || null,
        //         icon: item.icon || null,
        //         subCards: []
        //     };
    
        //     for (const key in item) {
        //         if (Array.isArray(item[key])) {
        //             newCard.subCards = this.recursiveCreateAdminCards(item[key], idCounter);
        //         }
        //     }
    
        //     return newCard;
        // });

        const levels = data.levels ?? [];
        const levelCategories = levels.reduce((acc, level) => {
            return acc + level.categories.length;
        }, 0);

        const categoryQuestions = levels.reduce((acc, level) => {
            return acc + level.categories.reduce((acc, category) => {
                return acc + category.questions.length;
            }, 0);
        }, 0);

        const newLevelData = [
            {
                id: data.id,
                title: data.name,
                icon: data.icon,
                button: {
                    text: 'Bewerken',
                    href: `/admin/games/edit?game=${data.id}`,
                },
                subTexts: [
                    `${levels.length} ${plurification(levels.length, 'Level', 'Levels')}`,
                    `${levelCategories} ${plurification(levelCategories, 'Categorie', 'Categorieën')}`,
                    `${categoryQuestions} ${plurification(categoryQuestions, 'Kaart', 'Kaarten')}`,
                ],
                subCards: levels.map((level) => {
                    const categoryQuestions = level.categories.reduce((acc, category) => {
                        return acc + category.questions.length;
                    }, 0);
                    const categories = level.categories ?? [];
                    return {
                        id: level.id,
                        title: level.name,
                        icon: level.icon,
                        button: {
                            href: `/admin/games/edit?game=${data.id}&level=${level.id}`,
                            text: 'Bewerken',
                        },
                        subTexts: [
                            `${categories.length} ${plurification(categories.length, 'Categorie', 'Categorieën')}`,
                            `${categoryQuestions} ${plurification(categoryQuestions, 'Kaart', 'Kaarten')}`,
                        ],
                        subCards: categories.map((category) => {
                            const questions = category.questions ?? [];
                            return {
                                id: category.id,
                                title: category.name,
                                button: {
                                    href: `/admin/games/edit?game=${data.id}&level=${level.id}&category=${category.id}`,
                                    text: 'Bewerken',
                                },
                                subTexts: [
                                    `${category.questions.length} ${plurification(category.questions.length, 'Kaart', 'Kaarten')}`,
                                ],
                                subCards: questions.map((question) => {
                                    return {
                                        id: question.id,
                                        title: question.name,
                                        subTextsAreHTML: true,
                                        subTexts: [
                                            // question.answer ?? ''
                                        ],
                                        button: {
                                            text: 'Bewerken',
                                            href: `/admin/games/edit?game=${data.id}&level=${level.id}&category=${category.id}&question=${question.id}`,
                                        },
                                    }
                                })
                            }
                        })
                    }
                })
            }
        ]
        return newLevelData;
    }
    
    recursiveCreateUserCards(gameEntry, data, parentType = null, parentId = null) {
        return data.map((item) => {
            let newCard = {
                id: item.id || null,
                title: item.name || null,
                icon: item.icon || null,
                subCards: []
            };
    
            // Set button
            let itemType = parentType;
            if (parentType === null) {
                itemType = "game";

                const allQuestions = this.generateQuestionsArray(item.levels);
                const allAnsweredQuestions = this.generateAnswererdQuestionsArray(allQuestions, gameEntry);

                const categoryCount = item.levels.reduce((acc, level) => {
                    return acc + level.categories.length;
                }, 0);

                newCard.progress = this.calculateProgress(allAnsweredQuestions.length, allQuestions.length);

                if (newCard.progress === 100) {
                    newCard.button = {
                        text: 'Reset',
                        onClick: async () => {
                            await this.resetGame();
                            window.location.reload();
                        },
                    };
                } else {
                    newCard.button = {
                        text: 'Go!',
                        href: '/game',
                    };
                }

                newCard.subTexts = [
                    `${item.levels.length} ${plurification(item.levels.length, 'Level', 'Levels')}`,
                    `${categoryCount} ${plurification(categoryCount, 'Categorie', 'Categorieën')}`,
                ];
            } else if (parentType === "game") {
                itemType = "level";

                const allQuestions = this.generateQuestionsArray([item]);
                const allAnsweredQuestions = this.generateAnswererdQuestionsArray(allQuestions, gameEntry);

                const questionCount = item.categories.reduce((acc, level) => {
                    return acc + level.questions.length;
                }, 0);

                newCard.button = {
                    text: 'Go!',
                    href: `/game?level=${item.id}`,
                };
                newCard.progress = this.calculateProgress(allAnsweredQuestions.length, allQuestions.length);
                newCard.subTexts = [
                    `${item.categories.length} ${plurification(item.categories.length, 'Categorie', 'Categorieën')}`,
                    `${questionCount} ${plurification(questionCount, 'Kaart', 'Kaarten')}`,
                ];
            } else if (parentType === "level") {
                itemType = "category";

                const allQuestions = item.questions;
                const allAnsweredQuestions = this.generateAnswererdQuestionsArray(allQuestions, gameEntry);

                newCard.button = {
                    text: 'Go!',
                    href: `/game?level=${parentId}&category=${item.id}`,
                };
                newCard.progress = this.calculateProgress(allAnsweredQuestions.length, allQuestions.length);
                newCard.subTexts = [
                    `${item.questions.length} ${plurification(item.questions.length, 'Kaart', 'Kaarten')}`,
                ];
            }
    
            // Recursively process sub-items
            for (const itemKey in item) {
                if (Array.isArray(item[itemKey])) {
                    // Pass the current type and id as context to the recursive call
                    if (itemType !== "category") {
                        // Don't generate question cards
                        newCard.subCards = this.recursiveCreateUserCards(gameEntry, item[itemKey], itemType, item.id);
                    }
                }
            }
    
            return newCard;
        });
    }



    // Gameplay functions
    getPlayableGame(levelID = null, categoryID = null) {
        return new Promise(async (resolve, reject) => {
            // Get game
            let game = null;
            try {
                game = await this.getGame();
            } catch (error) {
                const HandledError = new Error(`Catched: ${(typeof error === 'string') ? error : error.message}`);
                Bugsnag.notify(HandledError);
                console.error('Error -> GameWrapper.getPlayableGame().getGame()', error);

                reject(error);
            }

            // Get game entry
            let gameEntry = null;
            try {
                gameEntry = await this.getGameEntryByUserAndGameId();
            } catch (error) {
                const HandledError = new Error(`Catched: ${(typeof error === 'string') ? error : error.message}`);
                Bugsnag.notify(HandledError);
                console.error('Error -> GameWrapper.getPlayableGame().getGameEntryByUserAndGameId()', error);

                reject(error);
            }

            // Get level data
            let currentLevel = null;
            let prevLevelID = null;
            let nextLevelID = null;

            if (!levelID) {
                levelID = game.levels[0].id;
                currentLevel = game.levels[0];
            } else {
                currentLevel = game.levels.find((level) => level.id === levelID) ?? null;
            }

            const levelIndex = game.levels.findIndex((level) => level.id === levelID);
            if (levelIndex > 0) {
                prevLevelID = game.levels[levelIndex - 1].id;
            }
            if (levelIndex < game.levels.length - 1) {
                nextLevelID = game.levels[levelIndex + 1].id;
            }

            // Get category data
            let currentCategory = null;
            let prevCategoryID = null;
            let nextCategoryID = null;


            if (!categoryID) {
                categoryID = currentLevel.categories[0].id;
                currentCategory = currentLevel.categories[0];
            } else {
                currentCategory = currentLevel.categories.find((category) => category.id === categoryID) ?? null;
            }

            const categoryIndex = currentLevel.categories.findIndex((category) => category.id === categoryID);
            if (categoryIndex > 0) {
                prevCategoryID = currentLevel.categories[categoryIndex - 1].id;
            }
            if (categoryIndex < currentLevel.categories.length - 1) {
                nextCategoryID = currentLevel.categories[categoryIndex + 1].id;
            }


            // Get questions
            let currentQuestions = currentCategory.questions;
            currentQuestions = currentQuestions.map((question) => {
                const answeredQuestion = gameEntry.question_entries.find((entry) => {
                    return entry.answered_questions_id.question.id === question.id;
                });

                if (answeredQuestion) {
                    question.user_was_right = answeredQuestion.answered_questions_id.user_was_right;
                } else {
                    question.user_was_right = null;
                }

                return question;
            });

            // Sort all questions (answered questions first)
            currentQuestions.sort((a, b) => {
                if (a.user_was_right === null && b.user_was_right !== null) {
                    return 1;
                }
                if (a.user_was_right !== null && b.user_was_right === null) {
                    return -1;
                }
                return 0;
            });

            // Shuffle all unanswered questions (keep answered questions at start)
            const unansweredQuestions = currentQuestions.filter((question) => question.user_was_right === null);
            unansweredQuestions.sort(() => Math.random() - 0.5);
            currentQuestions = currentQuestions.map((question) => {
                if (question.user_was_right === null) {
                    return unansweredQuestions.shift();
                }
                return question;
            });


            // Progress
            const answeredQuestions = currentQuestions.filter((question) => question.user_was_right !== null);
            const progress = this.calculateProgress(answeredQuestions.length, currentQuestions.length);

            resolve({
                game: game,
                gameEntry: gameEntry,
                levelData: {
                    prevLevelID: prevLevelID,
                    currentLevel: currentLevel,
                    nextLevelID: nextLevelID,
                },
                categoryData: {
                    prevCategoryID: prevCategoryID,
                    currentCategory: currentCategory,
                    nextCategoryID: nextCategoryID,
                },
                currentQuestions: currentQuestions,
                answeredQuestions: answeredQuestions,
                progress: progress,
            });
        });
    }

    getGameEntryByUserAndGameId(isFirstTime = true) {
        return new Promise(async (resolve, reject) => {
            if (!this.user.id || !this.gameID) {
                reject('User ID or game ID is missing');
                return;
            }

            const filter = {
                user: {
                    _eq: this.user.id,
                },
                game: {
                    _eq: this.gameID,
                },
            };

            const args = {
                filter: filter,
                sort: ['-id'],
                fields: this.questionEntryFields,
            };

            try {
                // Get game entry
                const response = await this.sdkWrapper.request(readItems('user_game_entries', args));

                if (response.length > 0) {
                    resolve(response[0]);
                    return;
                }

                if (!isFirstTime) {
                    reject('Er is geen game entry gevonden voor deze gebruiker en dit spel.');
                    return;
                }

                // Create game entry if it doesn't exist
                try {
                    await this.sdkWrapper.request(createItem('user_game_entries', {
                        user: this.user.id,
                        game: this.gameID,
                    }));
                } catch (error) {
                    const HandledError = new Error(`Catched: ${(typeof error === 'string') ? error : error.message}`);
                    Bugsnag.notify(HandledError);
                    console.error('Error -> GameWrapper.getGameEntryByUserAndGameId().createItem()', error);

                    reject(error);
                    return;
                }

                // Get new game entry
                try {
                    const refetchResponse = await this.getGameEntryByUserAndGameId(false);
                    resolve(refetchResponse);
                } catch (error) {
                    const HandledError = new Error(`Catched: ${(typeof error === 'string') ? error : error.message}`);
                    Bugsnag.notify(HandledError);
                    console.error('Error -> GameWrapper.getGameEntryByUserAndGameId().refetch()', error);

                    reject(error);
                    return;
                }
            } catch (error) {
                const HandledError = new Error(`Catched: ${(typeof error === 'string') ? error : error.message}`);
                Bugsnag.notify(HandledError);
                console.error('Error -> GameWrapper.getGameEntryByUserAndGameId()', error);

                reject(error);
                return;
            }
        });
    }

    createQuestionEntry(questionId, userWasRight) {
        return new Promise(async (resolve, reject) => {
            if (!this.user.id) {
                reject('User ID is missing');
                return;
            }

            // Get users game entry
            const entry = await this.getGameEntryByUserAndGameId();

            // Check if question entry already exists
            const questionEntryExists = entry.question_entries.find((entry) => {
                return entry.answered_questions_id.question.id === questionId;
            });

            const existingQuestionEntries = entry.question_entries.map((entry) => {
                return entry.id;
            });

            if (questionEntryExists) {
                const junctionTableId = questionEntryExists.answered_questions_id.id;

                try {
                    this.sdkWrapper.request(updateItem('answered_questions', junctionTableId, {
                        user_was_right: userWasRight,
                    })).then((response) => {
                        resolve(response);
                    }).catch((error) => {
                        const HandledError = new Error(`Catched: ${(typeof error === 'string') ? error : error.message}`);
                        Bugsnag.notify(HandledError);
                        reject(error);
                    });
                } catch (error) {
                    const HandledError = new Error(`Catched: ${(typeof error === 'string') ? error : error.message}`);
                    Bugsnag.notify(HandledError);
                    console.error('Error -> GameWrapper.createQuestionEntry().updateItem()', error);

                    reject(error);
                }
            } else {
                this.sdkWrapper.request(updateItem('user_game_entries', entry.id, {
                    question_entries: [
                        ...existingQuestionEntries,
                        {
                            user_game_entries_id: entry.id,
                            answered_questions_id: {
                                question: questionId,
                                user_was_right: userWasRight,
                            },
                        }
                    ],
                })).then((response) => {
                    resolve(response);
                }).catch((error) => {
                    const HandledError = new Error(`Catched: ${(typeof error === 'string') ? error : error.message}`);
                    Bugsnag.notify(HandledError);
                    reject(error);
                });
            }
        });
    }

    handleAnswer(levelID = null, categoryID = null, questionID, answer) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.createQuestionEntry(questionID, answer);

                let playableGame = null;
                if (response) {
                    playableGame = await this.getPlayableGame(levelID, categoryID);
                }

                if (!playableGame) {
                    reject('Error -> GameWrapper.handleAnswer()');
                    return;
                }

                resolve(playableGame);
            } catch (error) {
                const HandledError = new Error(`Catched: ${(typeof error === 'string') ? error : error.message}`);
                Bugsnag.notify(HandledError);
                console.error('Error -> GameWrapper.handleAnswer()', error);

                reject(error);
            }
        });
    }

    getNextSlideIndex(game, swiper) {
        // Loop through slides from activeSlide
        for (let i = swiper.activeIndex; i < swiper.slides.length; i++) {
            const slide = swiper.slides[i];
            const questionID = parseInt(slide.getAttribute('data-question-id'));

            const question = game.currentQuestions.find((question) => question.id === questionID);
            if (question.user_was_right === null) {
                return i;
            }
        }

        // Loop through slides from start to activeSlide
        for (let i = 0; i < swiper.activeIndex; i++) {
            const slide = swiper.slides[i];
            const questionID = parseInt(slide.getAttribute('data-question-id'));

            const question = game.currentQuestions.find((question) => question.id === questionID);
            if (question.user_was_right === null) {
                return i;
            }
        }

        return null;
    }



    // Helper functions
    answered(question) {
        return question.user_was_right !== null;
    }

    answerRight(question) {
        return question.user_was_right === true;
    }

    answerWrong(question) {
        return question.user_was_right === false;
    }



    // Frontend functions
    getSlides(questions) {
        return new Promise((resolve, reject) => {
            const handleToggleAnswer = (questionID) => {
                const answerElement = document.getElementById(`answer-${questionID}`);
        
                if (answerElement) {
                    answerElement.classList.toggle('hidden');
                }

                const showAnswerButton = document.getElementById(`show-answer-button-${questionID}`);
                const hideAnswerButton = document.getElementById(`hide-answer-button-${questionID}`);

                if (showAnswerButton) {
                    showAnswerButton.classList.toggle('hidden');
                }
                if (hideAnswerButton) {
                    hideAnswerButton.classList.toggle('hidden');
                }
            };

            const slides = questions.map((question) => {
                return (
                    <div key={question.id} id={`question-${question.id}`} data-question-id={question.id} data-background={this.answerRight(question) ? 'green' : this.answerWrong(question) ? 'red' : 'primary'} className='p-5 rounded-xl flex-1'>
                        <div className='overflow-y-auto h-full relative flex gap-2 flex-col'>
                            <h4 className="text-base sm:text-xl md:text-2xl">{question.name}</h4>
    
                            <div className='flex-1 overflow-y-auto'>
                                <p id={`answer-${question.id}`} className={`${this.answered(question) ? '' : 'hidden'}`}>
                                    {question.answer}
                                </p>
                            </div>
    
                            <div className='flex gap-4 mt-auto'>
                                <Button id={`show-answer-button-${question.id}`} className={`w-full ${this.answered(question) ? 'hidden' : ''} `} colorType='primary' onClick={() => handleToggleAnswer(question.id)}>Bekijk antwoord</Button>
                                <Button id={`hide-answer-button-${question.id}`} className={`w-full ${this.answered(question) ? '' : 'hidden'}`} colorType='primary' onClick={() => handleToggleAnswer(question.id)}>Verberg antwoord</Button>
                            </div>
                        </div>
                    </div>
                );
            });
            
            resolve(slides);
        });
    }
}